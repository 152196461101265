<template>
  <Navbar />
  <router-view />
</template>
<script>
import Navbar from './views/navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
  }
}
</script>

<style>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.main-content {
  display: flex;
  padding: 20px;
}

.editor,
.output {
  flex: 1;
  margin: 10px;
}
</style>
